import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

export default {
  init() {
    // JavaScript to be fired on all pages
    const btns = document.querySelectorAll('.navbar-toggler');
    const nav = document.querySelector('.sidebar-menu');

    btns.forEach(function(btn) {
      btn.onclick = () => {
        btn.classList.toggle('is-active');
        nav.classList.toggle('is-open');
        document.body.classList.toggle('is-sidebar-open');
        document.body.classList.add('is-sidebar-animating');
      }
    });

    nav.addEventListener('transitionend', () => {
      document.body.classList.remove('is-sidebar-animating');
    });

    const slider = new Swiper('.o-slider', {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 1,
      spaceBetween: 30,
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      autoplay: {
        delay: 5000,
      },
    });
    console.log(slider);

    const reset = () => {
      const images = document.querySelectorAll('.gallery-image');
      images.forEach(image => {
        image.classList.remove('active')
      });
      const thumbs = document.querySelectorAll('.gallery-thumb');
      thumbs.forEach(thumb => {
        thumb.classList.remove('active')
      });
    }

    const thumbs = document.querySelectorAll('.gallery-thumb');
    thumbs.forEach(thumb => {
      thumb.addEventListener('click', function() {
        reset();
        const imageId = this.getAttribute('data-image');
        this.classList.add('active');
        document.getElementById(imageId).classList.add('active');
        console.log(this.getAttribute('data-image'));
      });
    });
 
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
